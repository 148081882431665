<template>
  <transition
    appear
    v-bind:css="false"
    v-on:before-enter="beforeEnter"
    v-on:enter="enter"
    v-on:leave="leave"
  >
    <div class="Plane">
      <div :onClick="backhome" class="back">
        <img
          :src="require('@/assets/images/UI/CTA/BacktoMap/Small.png')"
          alt="Back To Map"
        />
      </div>
      <div class="color__popup" v-if="selected">
        <div class="colorIntro" delay="5.0">
          <h3 v-html="selected.color_section.header"></h3>
          <p v-html="selected.color_section.copy"></p>
        </div>
        <div class="colorIntro" delay="3.0">
          <h3 v-html="selected.color_section.subheader"></h3>
        </div>
      </div>
      <div class="videopop" v-if="showVideo && selected">
        <div class="videopop__closeVideo" :onClick="closeVideoPopup">
          <img
            :src="require('@/assets/images/UI/X_Inverted.png')"
            alt="Back To Map"
          />
        </div>
        <div class="videopop__icon" :onClick="loadvideo">
          <img :src="require('@/assets/images/UI/CTA/PlayButton.png')" />
          <img :src="getURL(selected.video_thumb)" alt="Video Thumbnail" />
        </div>
        <p>{{ selected.video_copy }}</p>
      </div>
      <v-player v-if="showVideofull && selected" :selected="selected" />
      <v-loading class="loading" v-if="!loaded" />
    </div>
  </transition>
</template>
<style lang="scss" scoped>
.videopop {
  position: absolute;
  opacity: 0;
  pointer-events: auto;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: #fff;
  width: 80%;
  padding: 20px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  &__closeVideo {
    position: absolute;
    /* display: flex; */
    right: -10px;
    top: -10px;
    width: 50px;
    height: 50px;
    img {
      width: 100%;
    }
  }
  &__closeVideo p {
    width: 100%;
    /* text-align: left; */
    margin: 0 auto 0 auto;
    font-size: 30px;
    font-weight: 800;
  }
  &__icon {
    position: relative;
    /* flex: 1; */
    height: 120px;
    /* border-radius: 30px; */
    /* padding: 20px; */
    width: 120px;

    img {
      position: absolute;
      border-radius: 30px;
      width: 100%;
    }
    img:first-child {
      width: 50%;
      z-index: 10;
      /* margin: auto; */
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  p {
    width: 50%;
    padding: 10px;
  }
}
.color__popup {
  color: #fff;
  position: absolute;
  bottom: 71px;
  border-radius: 20px;
  border-radius: 10px;
  background-color: #dc0031;
  padding: 1rem;
  opacity: 0;
  text-align: center;
  width: 80%;
  left: 50%;
  transform: translate(-50%, 0);
}
.colorIntro {
  opacity: 1;
  display: none;
  margin: 0 auto;
  text-align: center;
  line-height: 17px;
  h3 {
    line-height: 25px;
    padding-bottom: 10px;
  }
}
.back {
  position: absolute;
  width: 100%;
  height: 50px;
  bottom: 20px;
  z-index: 30000;
  text-align: right;
  pointer-events: auto;
  img {
    height: 100%;
  }
}
.popover {
  pointer-events: none;
}
</style>
<script>
// @ is an alias to /src
import { gsap } from "gsap";
import router from "../router/index";
import {
  animateModel,
  modelFix,
  resetAudio,
  setAudio,
  playPauseSceneAudio,
} from "@/lib/helper";

import store from "../Store";

import Loading from "@/components/Loading";
import VideoPlayer from "@/components/Video";
export default {
  name: "Plane",
  components: {
    "v-player": VideoPlayer,
    "v-loading": Loading,
  },
  data: function() {
    return {
      endEvent: null,
      map: null,
      toucan: null,
      colorObjectA: null,
      colorObjectB: null,
      selectedColor: null,
      selected: null,
      showVideo: false,
      showVideofull: false,
      scene: document.getElementById("scene"),
      animationList: ["clip", "a"],
      txtTl: null,
      animation: null,
      allow_clicks: false,
      objLoaded: [],
      loaded: false,
    };
  },
  mounted() {
    this.selected = store.state.selected;
    this.objLoaded = [];
    // Tag
    window.webar.tracking.track_gamestate("entered_zoom");
    // const self = this;
    this.colorObjectA = document.getElementById("color_a");
    this.colorObjectB = document.getElementById("color_b");

    this.selectedColor = store.state.selected;
    console.log("this.selectedColor  ", this.selectedColor);

    this.colorObjectA.addEventListener("model-loaded", this.objectedLoaded);
    this.colorObjectB.addEventListener("model-loaded", this.objectedLoaded);
    // setTimeout(() => {
    resetAudio();
    setAudio();

    // }, 3);
    this.setModel();
  },
  updated() {
    console.log("updated!");
  },
  beforeUnmount: function() {
    console.log(
      ">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>|||||||||||beforeDestroy|||||||||||||>>>>>>>>>>>>>>>>>>>>>>>>>>>>>"
    );
    try {
      this.animation.kill();
    } catch (error) {
      console.log("error   ", error);
    }
    this.animation = null;
  },
  methods: {
    startIn() {
      // let test = this.colorObjectA.getObject3D("mesh");
      // gsap.set(test.material, { opacity: 0 });
      // gsap.to(test.material, { opacity: 1, duration: 5 });
      modelFix(this.colorObjectA);
      animateModel(this.colorObjectA, gsap, "set", { opacity: 0 });
      this.colorObjectA.setAttribute("visible", true);
      animateModel(this.colorObjectA, gsap, "to", {
        opacity: 1,
        duration: 1,
        ease: "none",
      });
      setTimeout(() => {
      modelFix(this.colorObjectB);
      animateModel(this.colorObjectB, gsap, "set", { opacity: 0 });
      this.colorObjectB.setAttribute("visible", true);
      animateModel(this.colorObjectB, gsap, "to", {
        opacity: 1,
        duration: 1,
        ease: "none",
      });
      }, 200);

      this.startAnimation();
      playPauseSceneAudio();
      this.addEvents();
    },
    objectedLoaded(e) {
      console.log("objectedLoaded   ", e);
      console.log("objectedLoaded  2 ", e.target);
      console.log("objectedLoaded  2 ", e.target.getAttribute("id"));

      this.objLoaded.push(e.target.getAttribute("id"));
      e.target.removeEventListener("model-loaded", this.objectedLoaded);
      if (
        this.objLoaded.indexOf("color_a") !== -1 &&
        this.objLoaded.indexOf("color_b") !== -1
      ) {
        this.loaded = true;
        this.startIn();
      }
    },
    loadvideo() {
      console.log("load Video", this.selected);
      // stop sound
      playPauseSceneAudio(true);
      this.showVideofull = true;
      console.log('this.showVideofull  ', this.showVideofull);
      window.webar.tracking.track_action(this.selected.tag_video);
    },
    getURL(image) {
      return require("@/assets/" + image);
    },
    getVideoURL(video) {
      return require("@/assets/" + video);
    },
    closeVideoPopup() {
      gsap.to(".videopop", {
        delay: 0.02,
        autoAlpha: 0,
        scale: 0.5,
        duration: 0.02,
      });
    },
    addEvents() {
      const self = this;
      gsap.set(".videopop", { autoAlpha: 0, scale: 0.5 });
      this.colorObjectB.addEventListener("click", function(ev) {
        ev.stopPropagation();
        console.log(self.allow_clicks, "CLICKED item", ev);

        const aEntity = document.querySelector("#rayMouse");
        const intersectedElement = ev && ev.detail && ev.detail.intersection;
        if (aEntity && intersectedElement === aEntity) {
          console.log("MATCH>>>>>>>>>>>>>>>");
        }

        window.webar.tracking.track_action(self.selected.tag_found);
        self.showVideo = true;
        gsap.set(".videopop", { autoAlpha: 0, scale: 0.5 });
        gsap.to(".videopop", {
          autoAlpha: 1,
          scale: 1,
          duration: 0.4,
        });
      });
    },
    startModelANimation() {
      setTimeout(() => {
        if (this.selectedColor.name == "Orange") {
          this.animation = gsap
            .timeline({ repeat: -1, repeatDelay: 5 })
            .clear()
            .to(this.colorObjectB.object3D.position, 0.2, {
              y: "+=.2",
              repeat: 1,
              yoyo: true,
              ease: "none",
            })
            .to(this.colorObjectB.object3D.position, 0.2, {
              y: "+=.2",
              repeat: 1,
              yoyo: true,
              ease: "none",
            })
            .to(this.colorObjectB.object3D.scale, 0.2, {
              x: "+=.2",
              y: "+=.2",
              z: "+=.2",
              repeat: 1,
              yoyo: true,
              ease: "none",
            });
        } else {
          this.animation = gsap
            .timeline({ repeat: -1, repeatDelay: 5 })
            .clear()
            .to(this.colorObjectB.object3D.position, 0.2, {
              y: "+=.2",
              repeat: 1,
              yoyo: true,
              ease: "none",
            })
            .to(this.colorObjectB.object3D.position, 0.2, {
              y: "+=.2",
              repeat: 1,
              yoyo: true,
              ease: "none",
            })
            .to(this.colorObjectB.object3D.rotation, 3, {
              y: "+=" + 360 * (Math.PI / 180),
              ease: "none",
            });
        }

        window.ani = this.animation;
        console.log("this.animation   ", this.animation);
      }, 1000);
    },
    startAnimation() {
      console.log("this.selectedColor.name   ", this.selectedColor.name);

      this.startModelANimation();
      // const self = this;
      const txtArray = document.querySelectorAll(".colorIntro");
      var tl = gsap.timeline({});
      // var tl = gsap.timeline({onComplete:this.startModelANimation.bind(this)});
      tl.to(".color__popup", { autoAlpha: 1, duration: 0.2 });
      txtArray.forEach((element) => {
        tl.set(element, { display: "block" });
        tl.to(element, { opacity: 1, duration: 1 });
        tl.to(element, {
          delay: element.getAttribute("delay"),
          opacity: 0,
          duration: 0.2,
        });
        tl.set(element, { display: "none" });
      });
      tl.to(".color__popup", { autoAlpha: 0, duration: 0.2 });

      tl.duration(15);
    },
    beforeEnter(el) {
      this.planeDetect = document.getElementById("welcome_nobg");
      this.map = document.getElementById("map");
      this.toucan = document.getElementById("toucan");
      // gsap.set(el, { opacity: 0 });
      animateModel(this.toucan, gsap, "set", { opacity: 0 });
      this.toucan.setAttribute("visible", false);
      animateModel(this.map, gsap, "set", { opacity: 0 });
      this.map.setAttribute("visible", false);
      console.log("leave el  ", el);

      console.log("beforeEnter el  ", el);
      gsap.to(".footer", { autoAlpha: 0, bottom: "-120%", duration: 0.5 });
    },
    enter(el, done) {
      // gsap.to(el, 1, { opacity:1, onComplete:done });
      console.log("enter el  ", el);
      done();
    },
    leave(el, done) {
      // gsap.to(el, 1, { opacity:0, onComplete:done });
      console.log("leave el  ", el);
      done();
    },
    backhome() {
      console.log("backhome   ");
      window.webar.tracking.track_action("select_plane_letsdunk");
      this.showVideofull = false;
      animateModel(this.colorObjectA, gsap, "to", {
        opacity: 0,
        duration: 0.2,
      });
      animateModel(this.colorObjectB, gsap, "to", {
        opacity: 0,
        duration: 0.2,
      });
      setTimeout(() => {
        store.selected = store.state.home;
        this.colorObjectA.setAttribute("visible", false);
        this.colorObjectB.setAttribute("visible", false);

        window.webar.tracking.track_action('back_to_map');
        router.push("intro");
      }, 1000 * 0.2);
    },
    finishAnimation: function() {
      console.log("FINISHED ANIMATION");
    },
    setModel: function() {
      this.colorObjectA.removeAttribute("gltf-model");
      this.colorObjectA.setAttribute("gltf-model", this.selectedColor.modelA);
      this.colorObjectB.removeAttribute("gltf-model");
      this.colorObjectB.setAttribute("gltf-model", this.selectedColor.modelB);

      console.log("this.selectedColor   ", this.selectedColor);
      if (this.selectedColor.position) {
        this.colorObjectB.setAttribute("position", this.selectedColor.position);
        this.colorObjectB.setAttribute("rotation", { x: 0, y: 0, z: 0 });
      } else {
        this.colorObjectB.setAttribute("position", { x: 0, y: 0, z: 0 });
      }
      window.mymodel = this.colorObjectB;
      console.log("Set Animation", this.colorObject);
    },
    transition: function() {
      console.log("transition");
    },
  },
};
</script>
