<template>
  <div class="permission-error-p pagefull">
    <div class="permission-error-p__overlay"></div>
    <div class="permission-error-p__message">
      <div>
        <div class="exit_img">
          <img
            class="Header___logo activate"
            :src="require('@/assets/images/UI/Header.png')"
            alt="1"
            :onClick="reCenter"
          />
        </div>

        <br /><br />
        <h2>Hungry for more Froot Loops fun?</h2>
        <br /><br />
        <div class="permission-error-p__message__buttons">
          <v-button :onClick="exit" kind="button__tertiary">
            <img
              :src="require('@/assets/images/UI/CTA/ExploreMore.png')"
              alt="Reload"
            />
          </v-button>
          <v-button :onClick="reload" kind="button__tertiary">
            <img
              :src="require('@/assets/images/UI/CTA/BacktoMap/Large.png')"
              alt="Exit"
            />
          </v-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button";
import { gsap } from "gsap";
import { externalLink, playCLickSound } from "@/lib/helper";

// import Permissions from "@/lib/Permissions";
import router from "../router/index";
export default {
  components: {
    "v-button": Button,
  },
  mounted() {
    gsap.set(".Header", { autoAlpha: 0 });
  },
  methods: {
    reload() {
      console.log("Reload map.");
      playCLickSound();
      gsap.to(".Header", {
        autoAlpha: 1,
        duration: 0.2,
        onComplete: function() {

          window.webar.tracking.track_action('exit_button_back_to_map');
          router.push("intro");
        },
      });

      // Permissions.permissions();
    },
    exit() {
      console.log("Exit to external link.");
      playCLickSound();
      gsap.to(".Header", {
        autoAlpha: 1,
        duration: 0.2,
        onComplete: function() {
          window.webar.tracking.track_action('exit_button_explore_more');
          window.location.href = externalLink();
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.permission-error-p {
  align-items: center;
  background-color: #00000080;
  background-size: cover;
  // background-image: url("~@/assets/images/Photo/SplashArt.jpg");
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  color: $white;
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  .exit_img {
    width: 80%;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }
  &__overlay {
    // background-color: $brandAccent;
    backdrop-filter: blur(5px);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
  }
  &__corner_image {
    position: absolute;
    bottom: 2%;
    z-index: 3;
    right: 2%;
  }
  &__message {
    color: $white;
    margin: 0;
    width: 100%;
    z-index: 3;
    align-items: center;
    display: flex;
    flex-direction: column;
    left: 0;
    padding: 50px 26px;
    justify-content: center;
    height: 100%;
    text-align: center;

    > * {
      margin-bottom: 10px;
      width: 100%;
    }

    &__info {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-top: 20px;

      > * {
        margin-left: 5px;

        &:last-child {
          margin-left: 0;
        }
      }
    }

    &__text {
      text-align: center;

      > p:first-child {
        margin-bottom: 20px;
      }
    }

    &__buttons {
      text-align: center;

      > *:first-child {
        margin-bottom: 20px;
      }
      img {
        width: 100%;
        max-width: 500px;
      }
    }
  }
}
</style>
