import { createRouter, createWebHashHistory } from 'vue-router';
import WebAR from '../views/WebAR.vue';
import Home from '../views/Home.vue';
import Error from '../views/Error.vue';
import Exit from '../views/Exit.vue';
// import Game from '../views/Game.vue';
// import Instructions from '../views/Instructions.vue';
import PermissionError from '../views/PermissionError.vue';
import PlaneDetection from '../views/PlaneDetection.vue';
import Intro from '../views/Intro.vue';
import Color from '../views/Color.vue';
import { externalLink } from "@/lib/helper";

import store from "../Store";

function saveGroup(to, from, next) {
  if (to.params.id) {
    localStorage.setItem('k_group', JSON.stringify(to.params))
    store.group = to.params;
  } else if (localStorage.getItem("k_group") != null) {
    store.group = JSON.parse(localStorage.getItem("k_group"));
  }
  if (store.state.group.id == "2") {
    require("../utils/Analytics");
  }else {
    window.webar = {
      init: function () {
      },
      tracking: {
        intervals: [1, 2, 3, 5, 10, 15, 30, 45, 60, 120],
        queue_time_events: function () {
        },
        track_action: function (name) {
          console.log(name)
        },
        track_gamestate: function (state) {
          console.log(state)
        }
      }
    }
  }
  next('/');
}
function checkGroup() {
  if (store.state.group === -1 && localStorage.getItem("k_group") != null) {
    store.group = JSON.parse(localStorage.getItem("k_group"));
  } else if(store.state.group === -1){
    window.location.href = externalLink();
  }
}
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    props:true,
    beforeEnter: checkGroup
  },
  {
    path: "/group/:id",
    name: "HomePage",
    component: Home,
    props: true,
    beforeEnter: saveGroup
  },
  {
    path: '/webar',
    name: 'WebAR',
    component: WebAR,
    children: [
      // {
      //   // UserProfile will be rendered inside User's <router-view>
      //   // when /user/:id/profile is matched
      //   path: '',
      //   component: BrandDetection
      // },
      {
        // UserPosts will be rendered inside User's <router-view>
        // when /user/:id/posts is matched
        path: 'start',
        component: PlaneDetection
      },
      {
        // UserPosts will be rendered inside User's <router-view>
        // when /user/:id/posts is matched
        path: 'intro',
        component: Intro
      },
      {
        // UserPosts will be rendered inside User's <router-view>
        // when /user/:id/posts is matched
        path: 'color',
        component: Color
      },
      {
        path: 'exit',
        name: 'Exit',
        component: Exit,
      },
      {
        path: '/error',
        name: 'Error',
        component: Error,
      }
    ]
  },
  // {
  //   path: '/webar',
  //   name: 'WebAR',
  //   component: WebAR,
  // },

  {
    path: '/permission-error',
    name: 'PermissionError',
    component: PermissionError,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
