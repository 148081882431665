<template>
  <transition
    appear
    v-bind:css="false"
    v-on:before-enter="beforeEnter"
    v-on:enter="enter"
    v-on:leave="leave"
  >
    <div class="Plane">
      <div class="cover" :onClick="setStage" ref="cover" />
      <div class="popup">
        <h3 class="audiotxt" delay="1.5">Hi Friends, it’s me! Toucan Sam!</h3>
        <h3 class="audiotxt" delay="1">And welcome to Froot Loops World:</h3>
        <h3 class="audiotxt" delay=".5">a place where endless possibilities</h3>
        <h3 class="audiotxt" delay=".5">are waiting to be discovered.</h3>
        <h3 class="audiotxt" delay=".5">So, if you're ready to join me</h3>
        <h3 class="audiotxt" delay="1">
          in a world full of colors, fun and Froot Loops.
        </h3>
        <h3 class="audiotxt" delay="1">
          all you have to do is follow your nose!
        </h3>
      </div>
      <v-footer class="footer_div" />
    </div>
  </transition>
</template>
<style lang="scss" scoped>
.popup {
  color: #fff;
  position: absolute;
  bottom: 0px;
  /* right: 0px; */
  margin: 0 auto;
  width: 100%;
  padding: 1rem;
}
.audiotxt {
  opacity: 1;
  display: none;
  width: 65%;
  margin: 0 auto;
  padding: 0.3rem 0.5rem;
  text-align: center;
  font-size: 18px;
  border-radius: 5px;
  background-color: #dc0031;
  font-size: 18px;
  line-height: normal;
}
.footer_div {
  bottom: -20;
}
</style>
<script>
// @ is an alias to /src
import { gsap } from "gsap";
import router from "../router/index";
import {
  animateModel,
  modelFix,
  resetAudio,
  setAudio,
  playPauseSceneAudio,
} from "@/lib/helper";

import store from "../Store";
import Footer from "@/components/Footer";
export default {
  name: "Plane",
  components: {
    "v-footer": Footer,
  },
  data: function() {
    return {
      endEvent: null,
      map: null,
      toucan: null,
      planeDetect: null,
      scene: document.getElementById("scene"),
      animationList: ["clip", "a"],
      txtTl: null,
    };
  },
  mounted() {
    store.selected = store.state.home;
    // Tag
    window.webar.tracking.track_gamestate("select_plane");
    const self = this;

    this.planeDetect = document.getElementById("welcome_nobg");
    modelFix(this.planeDetect);
    animateModel(this.planeDetect, gsap, "set", { opacity: 0 });
    this.planeDetect.setAttribute("visible", false);

    let colorObjectA = document.getElementById("color_a");
    let colorObjectB = document.getElementById("color_b");

    resetAudio();
    setAudio(store.state.toucanAudio);

    // Color Map
    modelFix(colorObjectA);
    animateModel(colorObjectA, gsap, "set", { opacity: 0 });
    colorObjectA.setAttribute("visible", false);

    modelFix(colorObjectB);
    animateModel(colorObjectB, gsap, "set", { opacity: 0 });
    colorObjectB.setAttribute("visible", true);

    // Main Map
    this.map = document.getElementById("map");
    modelFix(this.map);
    animateModel(this.map, gsap, "set", { opacity: 0 });
    this.map.setAttribute("visible", true);

    // Main Map
    this.toucan = document.getElementById("toucan");
    modelFix(this.toucan);
    animateModel(this.toucan, gsap, "set", { opacity: 0 });
    this.toucan.setAttribute("visible", true);

    console.log("mounted!>>>>>>>>>");

    // Event to catch end off animation
    if (this.endEvent == null) {
      this.endEvent = this.toucan.addEventListener(
        "animation-finished",
        self.finishAnimation.bind(self)
      );
    }

    console.log("mounted 2222!>>>>>>>>>");
    // Model animation
    this.transition();
    this.scene = document.getElementById("scene");
    console.log("mounted4444!>>>>>>>>>");
  },
  updated() {
    console.log("updated!");
  },
  methods: {
    beforeEnter(el) {
      // gsap.set(el, { opacity: 0 });
      console.log("beforeEnter el  ", el);
    },
    enter(el, done) {
      // gsap.to(el, 1, { opacity:1, onComplete:done });
      console.log("enter el  ", el);
      done();
    },
    leave(el, done) {
      // gsap.to(el, 1, { opacity:0, onComplete:done });
      animateModel(this.toucan, gsap, "set", { opacity: 0 });
      this.toucan.setAttribute("visible", false);
      animateModel(this.map, gsap, "set", { opacity: 0 });
      this.map.setAttribute("visible", false);
      console.log("leave el  ", el);
      done();
    },
    next: function() {
      window.webar.tracking.track_action("select_plane_letsdunk");
      this.courtSnd.play();
      router.push("select");
    },
    showMenu: function() {
      gsap.to(".popup", { autoAlpha: 0, duration: 0.5 });
      gsap.to(".footer", { autoAlpha: 1, bottom: 20, duration: 0.5 });
    },
    finishAnimation: function() {
      console.log("FINISHED ANIMATION");
      store.state.toucanAudio = true;

      setTimeout(() => {
        resetAudio();
        setAudio(store.state.toucanAudio);
        playPauseSceneAudio();
      }, 1000);
    },
    textAnimation: function() {
      console.log("textAnimation   ");
      this.txtTl = gsap.timeline({ repeat: 2, repeatDelay: 1 });
      const txtArray = document.querySelectorAll(".audiotxt");
      console.log("textAnimation   ", txtArray[0]);
      //add 3 tweens that will play in direct succession.
      var tl = gsap.timeline({ onComplete: this.showMenu });

      txtArray.forEach((element) => {
        tl.set(element, { display: "block" });
        tl.to(element, { opacity: 1, duration: 1 });
        tl.to(element, {
          delay: element.getAttribute("delay"),
          opacity: 0,
          duration: 0.2,
        });
        tl.set(element, { display: "none" });
      });
      tl.duration(16);
    },
    playAnimation: function(name) {
      console.log("Play Animation", name);
      this[name].setAttribute("animation-mixer", {
        clip: this.animationList[0],
        loop: "once",
        crossFadeDuration: 0.4,
      });
    },
    transition: function() {
      console.log("transition");

      // animateModel(this.planeDetect, gsap, "to", { opacity: 0, duration: 1 });
      animateModel(this.map, gsap, "to", { opacity: 1, duration: 1 });
      animateModel(this.toucan, gsap, "to", { opacity: 1, duration: 1 });
      // this.showMenu()
      setTimeout(() => {
        if (!store.state.toucanAudio) {
          playPauseSceneAudio();
          this.playAnimation("toucan");
          this.textAnimation();
        } else {
          playPauseSceneAudio();
          this.showMenu();
        }
      }, 10);
      this.map.addEventListener("model-loaded", (e) => {
        console.log("eee", e);
        console.log(e.target.components["gltf-model"].model.animations);
      });
    },
  },
};
</script>
