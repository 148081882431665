<template>
  <div class="carousel viewport main" ref="viewport">
    <!-- <main class="slides-container">
      <div id="picker" class="picker">
        <div class="cell slide" @click="()=>colorSelected(item)" :key="item.id" :cid="item.id" :color="item.color" v-for="item in list">
          <div class="cell-content">
            <div class="carousel___loop">
              <img
                :src="getURL(item.image)"
                :srcset="getSecondURL(item.image) + ' 2x'"
                alt="Froot Loop"
              />
            </div>
          </div>
        </div>
      </div>
    </main> -->
    <div class="scroller">
      <div class="gallery" ref="listContainer">
        <ul class="cards" ref="list">
          <li
            @click="() => colorSelected(item)"
            :key="item.id"
            :cid="item.id"
            :color="item.color"
            v-for="item in list"
          >
            <div class="icon_wrapper">
              <img
                :src="getURL(item.image)"
                :srcset="getSecondURL(item.image) + ' 2x'"
                alt="Froot Loop"
              />
            </div>
          </li>
        </ul>
        <!-- <div class="actions">
          <button class="prev">Prev</button>
          <button class="next">Next</button>
        </div> -->
      </div>
    </div>
    <div id="proxy" class="drag-proxy"></div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
import { InertiaPlugin } from "gsap/InertiaPlugin";
import store from "../../Store";
import router from "../../router/index";
import { playCLickSound } from "../../lib/helper"
export default {
  props: {
    cards: {
      type: Array,
      default: () => [],
    },
    onSelect: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      list: [],
      showHelp: true,
      countItems: null,
      items: null,
    };
  },
  computed: {
    count() {
      return this.list.length;
    },
  },
  methods: {
    getURL(image) {
      return require("@/assets/" + image);
    },
    getSecondURL(image) {
      const current =
        image.substring(0, image.lastIndexOf(".")) +
        "@2x" +
        image.substring(image.lastIndexOf("."));

      return require("@/assets/" + current);
    },
    colorSelected(e) {
      console.log("e   .>>>>>>>", e);
      // console.log("e   .>>>>>>>", e.target);
      playCLickSound();
      store.selected = e;
      window.webar.tracking.track_action(e.tag);
      router.push("color");
    },
    setMenu(selectedId) {
      let color = this.list[selectedId].color;
      gsap.to(this.items, {scale:.75, duration:.2});
      gsap.to(this.items[selectedId], {scale:1.25, duration:.2});
      this.onClickButton(this.list[selectedId]);
      gsap.to(".backgroundSVG", 1, {
        fill: color,
        delay: 0.5,
      });
    },
    onClickButton(value) {
      console.log("value  ", value);

      
      this.$emit("clicked", value);
    },
  },
  mounted() {
    console.log("Mounted sizeH  ", window.innerHeight);
    this.list = [...this.cards, ...this.cards];
    
    console.log("this.list   ", this.list);
    // this.list.unshift(this.list.pop());
    function resize() {
      let sizeH = window.innerHeight;
      console.log("sizeH  ", sizeH);
      // gsap.set(".gallery", { top: sizeH - 140 });
    }
    setTimeout(() => {
      this.showHelp = false;
    }, 2500);
    window.addEventListener("resize", resize);
    setTimeout(() => {
      this.items = this.$refs.list.querySelectorAll("li");
      // gsap.registerPlugin(ScrollTrigger, Draggable);
      this.setMenu(6)
      const proxy = document.createElement("div");
      // const items = this.$refs.list.querySelectorAll("li");
      const viewWidth = Math.min(window.innerWidth, 175);
      const itemWidth = (viewWidth * 100) / 100;
      const wrapWidth = this.count * itemWidth;
      const wrapVal = gsap.utils.wrap(0, wrapWidth);
      const offset = (itemWidth*5)+35;

      gsap.registerPlugin(Draggable, InertiaPlugin);

      gsap.set(this.$refs.list, {
        left: -(offset),
      });

      this.items.forEach((item, i) => {
        gsap.set(item, {
          x: i * itemWidth,
          // width: itemWidth,
        });
      });

      const animation = gsap.to(this.items, {
        duration: 1,
        x: `+=${wrapWidth}`,
        ease: "none",
        paused: true,
        modifiers: {
          x: function (x) {
            x = parseInt(x) % wrapWidth;
            // target.style.visibility =
            //   x - itemWidth > viewWidth ? "hidden" : "visible";
            return `${x}px`;
          },
        },
      });
      const self = this;
      Draggable.create(proxy, {
        type: "x",
        trigger: this.$refs.listContainer,
        inertia: true,
        onDrag: updateProgress,
        onThrowUpdate: updateProgress,
        snap: {
          x: (x) => {
            console.log('viewWidth  ',viewWidth);
            console.log('viewWidth  ',viewWidth);
            console.log('itemWidth  ',itemWidth);
            console.log('x  ',x);
            console.log('Math.round(x / itemWidth)  ',Math.round(x / itemWidth));
            console.log('Math.round(x / itemWidth) * itemWidth  ', Math.round(x / itemWidth) * itemWidth);
            let posX = Math.round(x / itemWidth) * itemWidth;
            let selectedNum = 0;
            let divSelected = 0;
            if(posX <= 0) {
              console.log(wrapWidth, 'AMOUNT of loops  >>>>>>>>>  ', (Math.floor(Math.abs(posX) / wrapWidth)));
              const loc = Math.abs(posX) - (Math.floor(Math.abs(posX) / wrapWidth) * wrapWidth);
              console.log('loc    ', loc);
              selectedNum = ( loc / itemWidth) % this.items.length;
              console.log('selectedNum    ', selectedNum);
              const oddEven = (Math.floor(Math.abs(posX) / (wrapWidth/2)) % 2);
              console.log(selectedNum, 'oddEven   >>>>>>>>>>', oddEven)
              if(oddEven == 0){
                divSelected = selectedNum + 6;
              } else {
                divSelected = selectedNum - 6;
              }
              
              console.log('X is lower>>>>>> so I made it positive  ', posX);
            } else {
              const loc = Math.abs(posX) - (Math.floor(Math.abs(posX) / wrapWidth) * wrapWidth);
              selectedNum = Math.round((wrapWidth - loc) / itemWidth) % this.items.length;

              console.log(wrapWidth, 'AMOUNT of loops  >>>>>>>>>  ', (Math.floor(Math.abs(posX) / wrapWidth)));
              console.log('loc    ', loc);
              // selectedNum = ( loc / itemWidth) % items.length;
              console.log('selectedNum    ', selectedNum);
              const oddEven = (Math.floor(Math.abs(posX) / (wrapWidth/2)) % 2);
              console.log(selectedNum, 'oddEven   >>>>>>>>>>', oddEven)
              if(oddEven == 0){
                divSelected = selectedNum - 6;
              } else {
                divSelected = selectedNum + 6;
              }

              console.log(this.items, 'X is Higher than 0>>>>>> so I made it positive  ', posX);
            }
            
            console.log(this.items, 'div Selected >>>>>>>>>>', this.items[selectedNum], selectedNum)
            self.setMenu(divSelected);
            return Math.round(x / itemWidth) * itemWidth;
          },
        },
      });

      function updateProgress() {
        animation.progress(wrapVal(this.x) / wrapWidth);
      }


    }, 4);
  },
};
</script>

<style lang="scss" scoped>
.carousel {
  z-index: 3;
}
* {
  box-sizing: border-box;
}
.scroller {
  position: relative;
  // background: #111;
  min-height: 100vh;
  width: 100vw;
}

// .gallery {
//   pointer-events: none;
// }
//
// .gallery * {
//   pointer-events: auto;
// }

.gallery {
  position: absolute;
  transform: translate(-50%, -90px);
  left: 50%;
  width: 470px;
  height: 235px;
  overflow: hidden;
  top: -70px;
}

.cards {
    position: relative;
    list-style: none;
    margin: 90px 0 0 0;
    padding: 0;
    height: 80%;
    font-size: 62px;
    line-height: 1;
    margin-bottom: 24px;
    color: #fff;

}

.cards li {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 175px;
  height: 175px;
  text-align: center;
  line-height: 18rem;
  font-size: 2rem;
  font-family: sans-serif;
  // background-color: #9d7cce;÷
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0.8rem;

        position: absolute;
      top: 0;
      left: 0;
      text-align: center;

  .icon_wrapper {
    border-radius: 50%;
    background-color: #ffffff50;
    /* padding: 15px; */
    width: 125px;
    height: 125px;
    margin: 0 auto;
    img {
      width: 80%;
      // margin: 15px 0 0 0;
      transform: translate(0%, -20%);
    }
  }
}

.actions {
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
}

button {
  display: inline-block;
  outline: none;
  border: none;
  padding: 8px 14px;
  background: #414141;
  background-image: -webkit-linear-gradient(top, #575757, #414141);
  background-image: -moz-linear-gradient(top, #575757, #414141);
  background-image: -ms-linear-gradient(top, #575757, #414141);
  background-image: -o-linear-gradient(top, #575757, #414141);
  background-image: linear-gradient(to bottom, #575757, #414141);
  text-shadow: 0px 1px 0px #414141;
  -webkit-box-shadow: 0px 1px 0px 414141;
  -moz-box-shadow: 0px 1px 0px 414141;
  box-shadow: 0px 1px 0px 414141;
  color: #ffffff;
  text-decoration: none;
  margin: 0 auto 10px;
  -webkit-border-radius: 4;
  -moz-border-radius: 4;
  border-radius: 4px;
  padding: 12px 25px;
  font-family: "Signika Negative", sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
  font-size: 13px;
  line-height: 18px;
}

button:hover {
  background: #57a818;
  background-image: -webkit-linear-gradient(top, #57a818, #4d9916);
  background-image: -moz-linear-gradient(top, #57a818, #4d9916);
  background-image: -ms-linear-gradient(top, #57a818, #4d9916);
  background-image: -o-linear-gradient(top, #57a818, #4d9916);
  background-image: linear-gradient(to bottom, #57a818, #4d9916);
  text-shadow: 0px 1px 0px #32610e;
  -webkit-box-shadow: 0px 1px 0px fefefe;
  -moz-box-shadow: 0px 1px 0px fefefe;
  box-shadow: 0px 1px 0px fefefe;
  color: #ffffff;
  text-decoration: none;
}

button {
  font-size: 20px;
  font-weight: 400;
}

a {
  color: #88ce02;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
.drag-proxy {
  visibility: hidden;
  position: absolute;
}
</style>
