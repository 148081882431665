<template>
  <div class="webAr pagefull" v-if="shouldShow3dView">
    <v-orientation v-if="landscape" />

    <v-header />
    <router-view class="popover"></router-view>
    <a-scene
      id="scene"
      vr-mode-ui="enabled: false"
      sortObjects: true
      raycaster="objects: .cantap;"
      renderer="maxCanvasWidth: 720; 
      maxCanvasHeight: 960; 
      antialias: true;"
      device-orientation-permission-ui="enabled: false"
      render-setting
      xrextras-gesture-detector
      xrextras-almost-there
      xrextras-loading
      xrweb="allowedDevices:any"
      :stats="showStats"
      cursor="fuse: false; rayOrigin: mouse;"
    >
    <!-- <a-entity id="rayMouse" cursor="fuse: false; rayOrigin:mouse" position="0 0 -1"
            geometry="primitive: ring; radiusInner: 0.02; radiusOuter: 0.03"
            material="color: black; shader: flat"></a-entity> -->


  <a-assets>
    <!-- <a-asset-item id="red_scale_a" src="assets/models/red_scale_a.glb"></a-asset-item>
    <a-asset-item id="red_scale_a" src="assets/models/red_scale_b.glb"></a-asset-item>
    <a-asset-item id="orange_scale_a" src="assets/models/orange_scale_a.glb"></a-asset-item>
    <a-asset-item id="orange_scale_a" src="assets/models/orange_scale_a.glb"></a-asset-item>
    <a-asset-item id="purple_scale_a" src="assets/models/purple_scale_a.glb"></a-asset-item>
    <a-asset-item id="purple_scale_a" src="assets/models/purple_scale_a.glb"></a-asset-item>
    <a-asset-item id="green_scale_a" src="assets/models/green_scale_a.glb"></a-asset-item>
    <a-asset-item id="green_scale_a" src="assets/models/green_scale_a.glb"></a-asset-item>
    <a-asset-item id="blue_scale_a" src="assets/models/blue_scale_a.glb"></a-asset-item>
    <a-asset-item id="blue_scale_a" src="assets/models/blue_scale_a.glb"></a-asset-item>
    <a-asset-item id="yellow_scale_a" src="assets/models/yellow_scale_a.glb"></a-asset-item>
    <a-asset-item id="yellow_scale_a" src="assets/models/yellow_scale_a.glb"></a-asset-item> -->

    <!-- <audio id="neigh" src="assets/sounds/snd_bg_red.m4a"></audio>
    <audio id="neigh" src="assets/sounds/snd_bg_orange.m4a"></audio>
    <audio id="neigh" src="assets/sounds/snd_bg_purple.m4a"></audio>
    <audio id="neigh" src="assets/sounds/snd_bg_green.m4a"></audio>
    <audio id="neigh" src="assets/sounds/snd_bg_blue.m4a"></audio>
    <audio id="neigh" src="assets/sounds/snd_bg_yellow.m4a"></audio> -->
  </a-assets>

      <a-camera 
           id="camera"
           near=".5"
           far="10000"
           position="0 5 8"
           cursor="fuse: false; rayOrigin: mouse;">
        <a-entity
          id="toucan"
          gltf-model="./assets/models/Intro_4b.glb"
          class="cantap"
          position="0 -.4 -2"
          rotation="0 0 0"
          scale="3 3 3"
          animation-mixer="clip: idle;"
          modify-materials
          visible="false"
          shadow>
        </a-entity>
      </a-camera>
      <!-- <a-cylinder id="welcome_nobg" material="color: #DC0031;" height=".01" position="0 0 0" radius=".5"></a-cylinder> -->
      <a-entity id="welcome_nobg" gltf-model="./assets/models/planeDetect.glb" shadow></a-entity>
        <!-- <a-sphere position="0 1.25 -5" radius="1.25" color="#EF2D5E"></a-sphere> -->

      <a-entity
        id="map"
        gltf-model="./assets/models/world_nofloor2.glb"
        shadow="receive: true"
        scale="3 3 3"
        position="0 -5 -20"
        rotation="0 20 0"
        visible="false"
      ></a-entity>
      <a-entity
        id="model"
        class="cantap"
          scale="3 3 3"
          position="0 0 0"
          rotation="0 15 0"
          shadow="receive: true"
          xrextras-hold-drag
          xrextras-two-finger-rotate
          xrextras-pinch-scale
          cursor="fuse: false; rayOrigin: mouse;"
      >
        <a-entity
          id="color_a"
          gltf-model=""
          shadow="receive: true"
          material="emissiveIntensity:2"
          visible="false"
        ></a-entity>
        <a-entity
          id="color_b"
          class=".cantap"
          gltf-model=""
          shadow="receive: true"
          visible="false"
        ></a-entity>
      </a-entity>

        <!-- position="0 0 -100"
        rotation="0 90 0"
        scale=".1 .1 .1" -->
      <a-entity
        light="type: directional;
         intensity: 1.0;
         castShadow: true;
         shadowMapHeight:2048;
         shadowMapWidth:2048;
         shadowCameraTop: 10;
         target: #model;"
        position="1 4.3 2.5"
        xrextras-attach="target: model; offset: 1 15 3;"
        shadow
      >
      </a-entity>

      <a-light type="ambient" intensity="0.1  "></a-light>

      <a-plane
        id="ground"
        rotation="-90 0 0"
        width="1000"
        height="1000"
        material="shader: shadow"
        shadow
      >
      </a-plane>

      <a-entity id="bgSound" sound="src: url(''); autoplay:false; positional:false"></a-entity>
      <a-entity id="fxSound" sound="src: url(''); autoplay:false; positional:false"></a-entity>
    </a-scene>
    <audio id="clickSnd">
      <source src="assets/sounds/click.mp3" type="audio/mpeg" />
    </audio>
    <!-- <v-footer class="footer" /> -->
  </div>
</template>
<style lang="scss">

#hudMain {
  position: fixed;
  bottom: 0px;
  z-index: 1000;
  margin: 10px auto;
}
.recorder-container {
  // background-color: red;
}
.playbut {
  width: 115%;
  height: 50px;
}
.footer_div {
  visibility: hidden;
  bottom: -120%;
}
.popover {

  width: 100%;

  z-index: 999;
  margin: 0 auto;
  height: 100%;
  // background-color: rebeccapurple;
  // display: flex;
  position: relative;
  position: absolute;
  bottom: 0;
  // pointer-events: none;
}
</style>
<script>
import store from "../Store";
import Orientation from "@/components/Orientation";

// import Footer from "@/components/Footer";
import Header from "@/components/Header";
// @ is an alias to /src
export default {
  name: "Home",
  components: {
    "v-orientation": Orientation,
    "v-header": Header,
  },
  data: function () {
    return {
      showStats: false,
      shouldShow3dView: true,
      idx: 0,
      landscape: false,
      maps: null,
      animationList: ["Intro", "a"],
      mainMap: false
    };
  },
  methods: {
    showModel: function () {
      console.log("showModel  ");
      // this.playAnimation('maps', 1)
    },
    setAnimation: function () {
      this.maps.removeAttribute("gltf-model");
      this.maps.setAttribute("gltf-model", "./assets/models/welcome.glb");
      this.maps.setAttribute("animation-mixer", { timeScale: 0 });
      console.log("Set Animation", this.maps);
    },
    playAnimation: function (name) {
      console.log("Play Animation", name);
      this[name].setAttribute("animation-mixer", {
        clip: this.animationList[0],
        loop: "repeat",
        crossFadeDuration: 0.4,
      });
    },
    playSound: function(name, action, speed = null){
      this[name].setAttribute("sound", {
        getPlaybackRate: speed ? speed : this.state.slowmo,
        volume: 1
      });

      if(action === "play"){
        this[name].components.sound.playSound();
      }else if(action === "stop") {
        this[name].components.sound.playStop();
      } else if(action === "pause") {
        this[name].components.sound.pauseStop();
      }
    },
    finishAnimation: function () {
      console.log("FINISHED ANIMATION");
    },
    orientationChange: function () {
      if (window.innerWidth > window.innerHeight) {
        this.landscape = true;
        store.landscape = true;
        console.log("landscape True");
      } else {
        console.log("landscape FALSE");
        this.landscape = false;
        store.landscape = false;
      }
    },
  },
  mounted: function () {
    this.maps = document.getElementById("modelall");
    console.log(document.querySelector('a-assets').fileLoader)
    window.addEventListener("resize", this.orientationChange);
    this.showModel();
  },
  unmounted: function () {
    window.removeEventListener("resize", this.orientationChange);
  },
};
</script>
