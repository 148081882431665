<template>
  <div class="VideoPlayer">
    <div class="VideoPlayer__Static" v-if="videoStateEnded">
      <!-- <img
        v-if="videoState"
        class="VideoPlayer__Static__videoicon"
        :src="require('@/assets/images/UI/CTA/PlayButton.png')"
        alt="Video Static"
      /> -->
      <img
        :onClick="replay"
        class="VideoPlayer__Static__videoicon"
        :src="require('@/assets/images/UI/CTA/ReplayButton.png')"
        alt="Video Static"
      />
      <img
        :onClick="replay"
        class="VideoPlayer__Static__videostatic"
        :src="getURL(selected.video_static)"
        alt="Video Static"
      />
    </div>
    <video
      id="videoplayer"
      v-if="!videoStateEnded"
      class="VideoPlayer__Element"
      width="320"
      height="240"
      controls
      autoplay
    >
      <source :src="getURL(selected.video)" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </div>
</template>

<script>
export default {
  name: "Video",
  props: {
    selected: {
      type: Object,
      default: () => {
        return { video: "", video_static: "" };
      },
    },
  },
  data: function() {
    return {
      videoObj: null,
      scene: null,
      videoStateEnded: false
    };
  },
  methods: {
    getURL(asset) {
      return require("@/assets/" + asset);
    },
    replay() {
      this.videoState = "Playing";
      this.videoObj.pause();
      this.videoObj.load();
      this.videoObj.play();
    }
  },
  mounted: function() {
    let self = this;
    this.scene = document.getElementById("scene");
    this.videoObj = document.getElementById("videoplayer");
    this.videoObj.onended = function(e) {
      self.videoStateEnded = true;
      /*Do things here!*/
      console.log(self.videoStateEnded, "Video Ended", e);
    };
    this.videoObj.onplaying = function(e) {
      self.videoStateEnded = false;
      /*Do things here!*/
      console.log(self.videoStateEnded, "Video playing", e);
    };
    this.videoObj.onpause = function(e) {
      self.videoStateEnded = false;
      /*Do things here!*/
      console.log(self.videoStateEnded, "Video paused", e);
    };
  },
};
</script>
<style scoped lang="scss">
.VideoPlayer {
  position: relative;
  z-index: 100;
  pointer-events: auto;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  &__Static {
    // display: none;
    width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #000;
    height: 50%;
    z-index: 200000;
    &__videostatic {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
    }
    &__videoicon {
      z-index: 200;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &__Coverup {
    position: absolute;
    width: 100%;
    height: 64px;
    background-color: $brandAccent;
    content: "";
    z-index: 0;
  }
  &__Element {
    width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: #000;
    height: 50%;
  }
  &___CTA {
    float: right;
    position: relative;
    padding: 10px 10px 0 0;
    text-align: right;

    .reset {
      width: 88px;
      height: 42px;
      margin: 0 10px;
    }
    .close {
      width: 42px;
      height: 42px;
    }
  }
}
.activate {
  pointer-events: auto;
}
.photoNote {
  background: rgb(0 0 0 / 33%);
  border-radius: 6px;
  -webkit-backdrop-filter: blur(23px);
  backdrop-filter: blur(23px);
  font-size: 1.2rem;
  color: #fff;
  padding: 10px;
  opacity: 0;
}
</style>
