
var store = {
  debug: false,
  motionPrompt: false,
  state: {
    toucanAudio: false,
    selected: null,
    active: null,
    static: null,
    capture: null,
    slowmo: .99,
    landscape: false,
    group: -1,
    intro:{
      id: 5,
      sound_bg: "assets/sounds/intro_song.m4a",
      sound_fx: "assets/sounds/intro_loop.m4a",
      sound: {
        bg: "assets/sounds/intro_song.m4a",
        loop: true,
        volume: 1
      },
    },
    home:{
      id: 5,
      sound_bg: "assets/sounds/Intro_Just_Sam.m4a",
      sound_fx: "assets/sounds/intro_loop.m4a",
      sound: {
        bg: "assets/sounds/intro_loop.m4a",
        talk: "assets/sounds/Intro_Just_Sam.m4a",
        loop: true,
        volume: 1
      },
    },
    talk_active: null
  },
  set toucanAudio(value) {
    if (this.debug) console.log('store.toucanAudio action triggered', value)
    this.state.toucanAudio = value;
  },
  set player(value) {
    if (this.debug) console.log('store.player action triggered', value)
    this.state.player = value;
  },
  set selected(value) {
    if (this.debug) console.log('store.dunk action triggered', value)
    this.state.selected = value;
  },
  set active(value) {
    if (this.debug) console.log('store.active action triggered', value)
    this.state.active = value;
  },
  set static(value) {
    if (this.debug) console.log('store.static action triggered', value)
    this.state.static = value;
  },
  set capture(value) {
    if (this.debug) console.log('store.capture action triggered', value)
    this.state.capture = value;
  },
  set slowmo(value) {
    if (this.debug) console.log('store.slowmo action triggered', value)
    this.state.slowmo = value;
  },
  set landscape(value) {
    if (this.debug) console.log('store.landscape action triggered', value)
    this.state.landscape = value;
  },
  set group(value) {
    if (this.debug) console.log('store.group action triggered', value)
    this.state.group = value;
  },
  set tony_talk_selected(value) {
    if (this.debug) console.log('store.tony_talk_selected action triggered', value)
    this.state.tony_talk_selected = value;
  },
  set shaq_talk_selected(value) {
    if (this.debug) console.log('store.shaq_talk_selected action triggered', value)
    this.state.shaq_talk_selected = value;
  },
  set talk_active(value) {
    if (this.debug) console.log('store.talk_active action triggered', value)
    this.state.talk_active = value;
  }
}

window.store = store;
export default store;