<template>
  <div class="footer">
    <div class="footer__wrapper">
      <v-carousel
        :cards="cards"
        :onSelect="next"
        @clicked="onClickChild"
      />
      <div class="footer___menubg">
        <svg
          class="footer___menubg___svg"
          width="125%"
          viewBox="0 0 505 159"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <g
            id="Page-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <path
              d="M252.419403,0 C373.11695,0 474.715783,67.3870677 504.838806,158.997669 L1.0658141e-14,158.997669 C30.1230235,67.3870677 131.721856,0 252.419403,0 Z"
              id="Combined-Shape"
              class="backgroundSVG"
              fill="#DC0031"
            ></path>
          </g>
        </svg>
      </div>
      <div class="footer___card">
        <!-- <div class="footer___carditem">
          <img
            class="footer___loop"
            :src="require('@/images/UI/menu/LOOP_red.png')"
            :srcset="require('@/images/UI/menu/LOOP_red@2x.png') + ' 2x'"
            alt="Froot Loop"
          />
        </div> -->
        <div class="footer___copy">
          <p>Tap to discover where</p>
          <h3 >{{selected.subcopy}}</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";

import Carousel from "@/components/Carousel";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
gsap.registerPlugin(DrawSVGPlugin);
//progressBar.style.strokeDashoffset = `${100 * timeLeft}`progressBar
import store from "../Store";

export default {
  name: "Footer",
  components: {
    "v-carousel": Carousel,
  },
  data: function() {
    return {
      slowmoOn: false,
      playItem: null,
      animationList: ["All Animations", "a"],
      idx: 0,
      tony: null,
      showInstructions: true,
      recordingInit: false,
      recording: false,
      captureComplete: false,
      state: store.state,
      recordItem: null,
      photoItem: null,
      tl: null,
      selected: {
          id: 0,
          name: "Flying Tiger",
          copy: "Tap to discover where",
          subcopy: "Red Froot Loops come from",
          image: "images/UI/menu/LOOP_red.png",
          background: "images/UI/menu/LOOP_red.png",
          model: "assets/models/red.glb",
          sound: "assets/sounds/tony_dunk1b_widetrim.m4a",
          tag: "tony_jam_select_1",
          cMainId: 0,
          distFromParentCenter: 0,
          startLeftDist: 0,
          isVisible: true,
          color: "#DC0031"
        },
      photoAudio: null,
      cards: [
        {
          id: 0,
          name: "Red",
          copy: "Tap to discover where",
          subcopy: "red Froot Loops come from",
          color_section: {
            header: "Welcome to the<br />Colorful Forest!",
            copy: "Move your phone to explore<br />the fruity tree tops.",
            subheader: "Find Toucan Sam’s<br />red portal."
          },
          image: "images/UI/menu/Red-Loop.png",
          background: "images/UI/menu/Red-Loop.png",
          video: "videos/red.mp4",
          video_thumb: "images/video_thumb/1x1/red.jpg",
          video_static: "images/video_thumb/16x9/red.jpg",
          video_copy: "Way to go! Click to see the full story behind the red Froot Loops.",
          modelA: "assets/models/red_scale_a.glb",
          modelB: "assets/models/red_scale_b.glb",
          position: {x:0.4, y:1, z:-0.95},
          model: "assets/models/red.glb",
          sound: {
            bg: "assets/sounds/snd_bg_red.m4a",
            loop: true,
            volume: 1
          },
          sound_bg: "assets/sounds/snd_bg_red.m4a",
          sound_fx: "assets/sounds/snd_fx_red.m4a",
          tag: "loop_select_red",
          tag_found: "loop_found_red",
          tag_video: "video_played_red",
          cMainId: 0,
          distFromParentCenter: 0,
          startLeftDist: 0,
          isVisible: true,
          color: "#DC0031"
        },
        {
          id: 1,
          name: "Orange",
          copy: "Tap to discover where",
          subcopy: "orange Froot Loops come from",
          color_section: {
            header: "Dig into the<br />Crunchy Caverns.",
            copy: "Move your phone to explore<br />the colorful, crunchy caverns.",
            subheader: "Find Toucan Sam’s<br />orange portal."
          },
          image: "images/UI/menu/Orange-Loop.png",
          background: "images/UI/menu/Orange-Loop.png",
          video: "videos/orange.mp4",
          video_thumb: "images/video_thumb/1x1/orange.jpg",
          video_static: "images/video_thumb/16x9/orange.jpg",
          video_copy: "Great! Click to see the full story behind the orange Froot Loops.",
          modelA: "assets/models/orange_scale_a.glb",
          modelB: "assets/models/orange_scale_b.glb",
          position: {x:0.31011, y:1.2176, z:0.2657},
          sound: {
            bg: "assets/sounds/snd_bg_orange.m4a",
            loop: true,
            volume: 1
          },
          sound_bg: "assets/sounds/snd_bg_orange.m4a",
          sound_fx: "assets/sounds/snd_fx_orange.m4a",
          tag: "loop_select_orange",
          tag_found: "loop_found_orange",
          tag_video: "video_played_orange",
          cMainId: 1,
          distFromParentCenter: 0,
          startLeftDist: 0,
          isVisible: true,
          color: "#F98D29"
        },
        {
          id: 2,
          name: "Yellow",
          copy: "Tap to discover where",
          subcopy: "yellow Froot Loops come from",
          color_section: {
            header: "Look around the<br />Happy Hilltops!",
            copy: "Move your phone to explore<br />the fruit-filled hilltops.",
            subheader: "Find Toucan Sam’s<br />yellow portal."
          },
          image: "images/UI/menu/Yellow-Loop.png",
          background: "images/UI/menu/Yellow-Loop.png",
          video: "videos/yellow.mp4",
          video_thumb: "images/video_thumb/1x1/yellow.jpg",
          video_static: "images/video_thumb/16x9/yellow.jpg",
          video_copy: "Stellar! Click to see the full story behind the yellow Froot Loops!",
          modelA: "assets/models/yellow_scale_a.glb",
          modelB: "assets/models/yellow_scale_b.glb",
          position: {x:-0.028814, y:1.1323, z:-0.22453},
          sound: {
            bg: "assets/sounds/snd_bg_yellow.m4a",
            loop: true,
            volume: 1
          },
          sound_bg: "assets/sounds/snd_bg_yellow.m4a",
          sound_fx: "assets/sounds/snd_fx_yellow.m4a",
          tag: "loop_select_yellow",
          tag_found: "loop_found_yellow",
          tag_video: "video_played_yellow",
          cMainId: 2,
          distFromParentCenter: 0,
          startLeftDist: 0,
          isVisible: true,
          color: "#FFD700"
        },
        {
          id: 3,
          name: "Green",
          copy: "Tap to discover where",
          subcopy: "green Froot Loops come from",
          color_section: {
            header: "Discover the<br />Great Froot Canyon!",
            copy: "Move your phone to explore<br />the rainbow-colored desert.",
            subheader: "Find Toucan Sam’s<br />green portal."
          },
          image: "images/UI/menu/Green-Loop.png",
          background: "images/UI/menu/Green-Loop.png",
          video: "videos/green.mp4",
          video_thumb: "images/video_thumb/1x1/green.jpg",
          video_static: "images/video_thumb/16x9/green.jpg",
          video_copy: "Nice job! Click to see the full story behind the green Froot Loops.",
          modelA: "assets/models/green_scale_a.glb",
          modelB: "assets/models/green_scale_b.glb",
          position: {x:0.1622, y:1.0207, z:-0.63694},
          sound: {
            bg: "assets/sounds/snd_bg_green.m4a",
            loop: true,
            volume: 1
          },
          sound_bg: "assets/sounds/snd_bg_green.m4a",
          sound_fx: "assets/sounds/snd_fx_green.m4a",
          tag: "loop_select_green",
          tag_found: "loop_found_green",
          tag_video: "video_played_green",
          cMainId: 2,
          distFromParentCenter: 0,
          startLeftDist: 0,
          isVisible: true,
          color: "#6CC049"
        },
        {
          id: 4,
          name: "Blue",
          copy: "Tap to discover where",
          subcopy: "blue Froot Loops come from",
          color_section: {
            header: "Loopy Island<br />straight ahead!",
            copy: "Move your phone to explore<br />the color bursting island.",
            subheader: "Find Toucan Sam’s<br />blue portal."
          },
          image: "images/UI/menu/Blue-Loop.png",
          background: "images/UI/menu/Blue-Loop.png",
          video: "videos/blue.mp4",
          video_thumb: "images/video_thumb/1x1/blue.jpg",
          video_static: "images/video_thumb/16x9/blue.jpg",
          video_copy: "You did it! Click to see the full story behind the blue Froot Loops.",
          modelA: "assets/models/blue_scale_a.glb",
          modelB: "assets/models/blue_scale_b.glb",
          position: {x:0.1759, y:1.9543, z:-0.7741},
          model: "assets/models/blue.glb",
          sound: {
            bg: "assets/sounds/snd_bg_blue.m4a",
            loop: true,
            volume: 1
          },
          sound_bg: "assets/sounds/snd_bg_blue.m4a",
          sound_fx: "assets/sounds/snd_fx_blue.m4a",
          tag: "loop_select_blue",
          tag_found: "loop_found_blue",
          tag_video: "video_played_blue",
          cMainId: 2,
          distFromParentCenter: 0,
          startLeftDist: 0,
          isVisible: true,
          color: "#40B4E5"
        },
        {
          id: 5,
          name: "Over the Mountain",
          copy: "Tap to discover where",
          subcopy: "purple Froot Loops come from",
          color_section: {
            header: "Greetings from<br />Mount Looperest!",
            copy: "Move your phone to explore<br />the happiest ice mountain of all time.",
            subheader: "Find Toucan Sam's<br />purple portal."
          },
          image: "images/UI/menu/Purple-Loop.png",
          background: "images/UI/menu/Purple-Loop.png",
          video: "videos/purple.mp4",
          video_thumb: "images/video_thumb/1x1/purple.jpg",
          video_static: "images/video_thumb/16x9/purple.jpg",
          video_copy: "Woohoo! Click to see the full story behind the purple Froot Loops.",
          modelA: "assets/models/purple_scale_a.glb",
          modelB: "assets/models/purple_scale_b.glb",
          position: {x:0.57432, y:0.89526, z:0.62515},
          sound: {
            bg: "assets/sounds/snd_bg_purple.m4a",
            loop: true,
            volume: 1
          },
          sound_bg: "assets/sounds/snd_bg_purple.m4a",
          sound_fx: "assets/sounds/snd_fx_purple.m4a",
          tag: "loop_select_purple",
          tag_found: "loop_found_purple",
          tag_video: "video_played_purple",
          cMainId: 2,
          distFromParentCenter: 0,
          startLeftDist: 0,
          isVisible: true,
          color: "#6A478D"
        },
      ]
    };
  },
  methods: {
    onClickChild (value) {
      console.log(value) // someValue
      this.selected = value;
    }
  },
  mounted: function() {},
  unmounted: function() {},
};
</script>
<style scoped lang="scss">
.footer {
  position: absolute;
  // top:0px;
  // bottom: 20px;
  // visibility: hidden;
  // bottom: -120%;
  width: 100%;
  height: 180px;
  background: url("/images/Icon/Lower_Third_Dots.png") bottom left
    no-repeat;
        z-index: 200000;

  &__wrapper {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    position: absolute;
    bottom: 0;
    height: 128px;
    width: 100%;
  }
  &___menubg {
    position: absolute;
    width: 100%;
    &___svg {
      position: relative;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
    }
  }
  &___copy {
    position: absolute;
    line-height: 18px;
    font-size: 20px;
    color: #fff;
    z-index: 1;
    text-align: center;
    margin: 0 auto;
    width: 100%;
    bottom: 0px;
    text-shadow: 0px 0px 5px #00000070;
  }
  &___card {
    z-index: 2;
    width: 100%;
  }
  &___carditem {
    display: flex;
    margin: -88px;
  }
  &___loop {
    margin: 0 auto;
    background-color: #ffffff59;
    border-radius: 50%;
    padding: 16px;
  }
}
</style>
