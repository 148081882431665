<template>
  <div class="permission-error-p pagefull">
    <div class="permission-error-p__overlay"></div>
    <div class="permission-error-p__message">
      <div>
        <h1>We need your permission</h1>
        <br /><br />
        <p>
          Your permission is needed to access this portion of the experience.
          Please reload the page and try again.
        </p>
        <br /><br />
        <p>
          If you continue to have issues, clear your history and website data.
        </p>
        <br /><br />
        <div class="permission-error-p__message__buttons">
          <v-button :onClick="reload" kind="button__tertiary">
            <img
              :src="require('@/assets/images/UI/CTA/ReloadPage.png')"
              alt="Reload"
            />
          </v-button>
          <v-button :onClick="exit" kind="button__tertiary">
            <img
              :src="require('@/assets/images/UI/CTA/ExitToSite.png')"
              alt="Exit"
            />
          </v-button>
        </div>
      </div>
    </div>
    <img class="permission-error-p__corner_image" :src="require('@/assets/images/UI/error_corner.png')" />
  </div>
</template>

<script>
import Button from "@/components/Button";

import { externalLink } from "@/lib/helper";

import store from "../Store";
// import Permissions from "@/lib/Permissions";
// import router from "../router/index";
export default {
  components: {
    "v-button": Button,
  },
  mounted() {
    window.webar.tracking.track_gamestate("permission_error");
  },
  methods: {
    reload() {
      console.log("Button click.");
      // window.webar.tracking.track_action("permission_error_reload");
      // window.location.reload(true);
      // var versionUpdate = (new Date()).getTime();
      let url = `https://${window.location.hostname}${
        window.location.port != "" ? ":" + window.location.port : ""
      }/#/group/${store.state.group.id}/${store.state.group.shaq}`;

      console.log("url   ", url);
      window.open(url, "_blank");
      // Permissions.permissions();
    },
    exit() {
      window.webar.tracking.track_action('exit');
      window.location.href = externalLink();
    },
  },
};
</script>
<style lang="scss" scoped>
.permission-error-p {
  align-items: center;
  background-color: $black;
  background-size: cover;
  // background-image: url("~@/assets/images/Photo/SplashArt.jpg");
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  color: $white;
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  justify-items: center;

  &__overlay {
    background-color: $brandAccent;
    backdrop-filter: blur(5px);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
  }
  &__corner_image {
    position: absolute;
    bottom: 2%;
    z-index: 3;
    right: 2%;
  }
  &__message {
    color: $white;
    margin: 0;
    width: 100%;
    z-index: 3;
    align-items: center;
    display: flex;
    flex-direction: column;
    left: 0;
    padding: 50px 26px;
    justify-content: center;
    height: 100%;
    text-align: center;

    > * {
      margin-bottom: 10px;
      width: 100%;
    }

    &__info {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-top: 20px;

      > * {
        margin-left: 5px;

        &:last-child {
          margin-left: 0;
        }
      }
    }

    &__text {
      text-align: center;

      > p:first-child {
        margin-bottom: 20px;
      }
    }

    &__buttons {
      text-align: center;

      > *:first-child {
        margin-bottom: 20px;
      }
      img {
        width: 100%;
        max-width: 500px;
      }
    }
  }
}
</style>
