<template>
  <transition
    appear
    v-bind:css="false"
    v-on:before-enter="beforeEnter"
    v-on:enter="enter"
    v-on:leave="leave"
  >
    <div class="Plane">
      <div class="cover" :onClick="setStage" ref="cover" />
      <div class="tap activate" :onClick="setStage" id="tap" ref="pop">
        <p>Move your device to a flat surface. <br />Tap to place.</p>
      </div>
    </div>
  </transition>
</template>
<style lang="scss" scoped>
.Brand {
  position: absolute;
  bottom: -90px;
  align-self: flex-end;
  justify-self: flex-end;
}
.tap {
  width: 100%;
  text-align: center;
  color: #fff;
  position: absolute;
  bottom: 10%;
  p {
    width: 70%;
    margin: 0 auto;
    background-color: #00000040;
    padding: 3px 5px;
  }
}
.letsgo {
  text-align: center;
  width: 100%;
  bottom: 0px;
  pointer-events: auto;
  padding: 0 0 2rem 0;
  filter: drop-shadow(0 10px 0.75rem rgba(0, 0, 0, 0.53));
}
.cover {
  width: 100%;
  height: 100%;
  pointer-events: auto;
}
.activate {
  pointer-events: auto;
}
#countdown {
  position: absolute;
  text-align: center;
  top: 85px;
  width: 100%;
  font-size: 10rem;

  z-index: 2000;
}
</style>
<script>
// @ is an alias to /src
import { gsap } from "gsap";
import router from "../router/index";

import store from "../Store";
import { animateModel, modelFix, playCLickSound } from "@/lib/helper";
export default {
  name: "Plane",
  components: {},
  data: function() {
    return {
      instruct: new gsap.timeline({
        paused: false,
      }),
      shouldShow3dView: true,
      stageInit: false,
      timeRecenter: null,
      planeDetect: null,
      scene: document.getElementById("scene"),
    };
  },
  mounted() {
    window.webar.tracking.track_gamestate("plane_found");
    this.planeDetect = document.getElementById("welcome_nobg");
    console.log("store.state   ", store.state);
    store.selected = store.state.intro;
    modelFix(this.planeDetect);
    // Main Map
    animateModel(this.planeDetect, gsap, "set", { opacity: 0 });
    this.planeDetect.setAttribute("visible", true);

    console.log("mounted!>>>>>>>>>");
    // instructions__icon

    let tl = gsap.timeline();
    gsap.set([this.$refs.pop, this.$refs.cover], { autoAlpha: 0 });
    //add 3 tweens that will play in direct succession.
    tl.to(this.$refs.pop, { delay: 2, duration: 1, autoAlpha: 1 });
    tl.to(this.$refs.cover, { duration: 1, autoAlpha: 1 }, "<");
    console.log("mounted 2222!>>>>>>>>>");
    // Model animation
    this.transition();
    console.log("mounted!33333>>>>>>>>>");
    this.timeRecenter = setInterval(this.reCenter, 1000);
    this.scene = document.getElementById("scene");

    this.planeDetect.addEventListener("model-loaded", this.animateIn);

    console.log("mounted4444!>>>>>>>>>");
  },
  updated() {
    console.log("updated!");
  },
  methods: {
    animateIn() {
      this.animation = gsap
        .timeline({ repeat: -1, repeatDelay: 2 })
        .clear()
        .to(this.planeDetect.object3D.position, 0.2, {
          y: "+=.8",
          repeat: 1,
          yoyo: true,
          ease: "none",
        })
        .to(this.planeDetect.object3D.position, 0.2, {
          y: "+=.8",
          repeat: 1,
          yoyo: true,
          ease: "none",
        })
        .to(this.planeDetect.object3D.rotation, 3, {
          y: "+=" + 360 * (Math.PI / 180),
          ease: "none",
        });
    },
    beforeEnter(el) {
      // gsap.set(el, { opacity: 0 });
      console.log("beforeEnter el  ", el);
    },
    enter(el, done) {
      // gsap.to(el, 1, { opacity:1, onComplete:done });
      console.log("enter el  ", el);
      done();
    },
    leave(el, done) {
      // gsap.to(el, 1, { opacity:0, onComplete:done });
      console.log("leave el  ", el);
      done();
    },
    next: function() {
      window.webar.tracking.track_action("select_plane_letsdunk");
      // set all sound
      playCLickSound();
      this.planeDetect.removeEventListener("model-loaded", this.animateIn);
      router.push("select");
    },
    transition: function() {
      console.log("transition");

      animateModel(this.planeDetect, gsap, "to", { opacity: 1, duration: 1 });
    },
    reCenter: function() {
      this.scene = document.getElementById("scene");
      console.log("RECENTER", this.scene);
      this.scene.emit("recenter", {});
    },
    setStage: function() {
      console.log("setStage");
      playCLickSound();
      window.webar.tracking.track_action("select_plane_set");

      // this.sndIntro = document.getElementById("sndIntro");
      // this.sndIntro.setAttribute("sound", {
      //   getPlaybackRate: 1,
      //   volume: 1
      // });
      // console.log('this.sndIntro   ', this.sndIntro);

      // this.sndIntro.components.sound.pauseSound();

      if (this.stageInit) {
        this.reCenter();
        return;
      }
      this.stageInit = true;
      clearInterval(this.timeRecenter);
      try {
        // if (XR8 && XR8.Threejs.xrScene()) {
        //   const { scene, camera, renderer } = XR.Threejs.xrScene();
        console.log("recenter");
        let tl = gsap.timeline();
        this.reCenter();

        tl.to(this.planeDetect, { duration: 0.5, autoAlpha: 0 });
        tl.to(this.$refs.cover, { duration: 0.5, autoAlpha: 0 }, "<");

        animateModel(
          this.planeDetect,
          tl,
          "to",
          { opacity: 1, duration: 1 },
          "<"
        );
        router.push("intro");
      } catch (e) {
        console.log("ERROR");
      }
    },
  },
};
</script>
