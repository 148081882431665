import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
require("aframe-orbit-controls");
import * as THREE from 'three';
// import * as THREE from 'three';

createApp(App)
.use(router).mount('#app');

const AFRAME = window.AFRAME;

AFRAME.registerComponent('loaded-emit', {
  init: function () {
    // Wait for model to load.
    // console.log('modify-materials   ');
    this.el.addEventListener('model-loaded', (e) => {
      // console.log('model-loaded   ');
      // Grab the mesh / scene.
      const obj = this.el.getObject3D('mesh');
      console.log(e,'model-loaded obj  ', obj);
      console.log('this  ', this);
      this.$emit("model_done_loading", obj);
    });
  }
});
  AFRAME.registerComponent('modify-materials', {
    init: function () {
      // Wait for model to load.
      // console.log('modify-materials   ');
      this.el.addEventListener('model-loaded', () => {
        // console.log('model-loaded   ');
        // Grab the mesh / scene.
        const obj = this.el.getObject3D('mesh');
        // Go over the submeshes and modify materials we want.
        obj.traverse(node => {
          // console.log('node.name   ', node.name);
          // node.material.transparent = true;
          // node.material.alphaTest = 0.5;
          // node.material.depthWrite = false;
          // if (node.name.indexOf('skeleton_#4') !== -1) {
          //   console.log('node.name   ', node);
          // }

          node.frustumCulled = false;
          if (node.material) {
            node.material.side = THREE.FrontSide;
            node.material.transparent = false;
            // console.log('node.name   ', node);
            if (node.material.name === 'Eye_Outer_part' || node.material.name === 'Eye_white_shader') {
              node.material.side = THREE.DoubleSide;
              node.material.transparent = true;
              // node.frustumCulled = true;
              // node.visible = false;
            }

          }
        });
      });
    }
  });
  AFRAME.registerComponent('render-setting', {
    init: function () {
      // console.log('render>>>>>>>>', this.el.renderer);
      this.el.renderer.outputEncoding = THREE.sRGBEncoding;
      this.el.renderer.shadowMap.enabled = true;
      this.el.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
      this.el.renderer.gammaOutput = true;
      this.el.renderer.gammaFactor = 2.2;
      this.el.renderer.sortObjects = false;
    }
  });