// import store from '../Store';
// import Vue from 'vue';
import router from "../router/index";
export default class Permissions {
  // Check and call all permissions
  static permissions() {
    Permissions.motionAllowed = false;
    Permissions.cameraAllowed = false;

    if (!Permissions.motionAllowed) {
      // if motion not currently allowed,  get permissions then continue to camera
      Permissions.motion();
    } else if (!Permissions.cameraAllowed) {
      // if motion is aready allowed, start with camera
      Permissions.camera();
    } else {
      // everythign is already allowed
      Permissions.success();
    }
  }

  static motion() {
    // Check if is IOS 13
    if (
      window.DeviceMotionEvent &&
      typeof window.DeviceMotionEvent.requestPermission === "function"
    ) {
      window.DeviceMotionEvent.requestPermission()
        .then(response => {
          console.log(response);
          if (response !== "granted") {
            console.log("DeviceMotion permissions not granted.");
            Permissions.handleError(response);
          } else {
            Permissions.motionAllowed = true;

            // continue on to camera permissions if its not currently active
            if (!Permissions.cameraAllowed) {
              Permissions.camera();
            } else {
              Permissions.success();
            }
          }
        })
        .catch(e => {
          Permissions.handleError(e);
        });
    } else {
      if (!Permissions.cameraAllowed) {
        Permissions.camera();
      } else {
        Permissions.success();
      }
    }
  }

  static camera() {
    console.log("[[CAMERA PERMISSIONS]]");
    if (
      "mediaDevices" in navigator &&
      "getUserMedia" in navigator.mediaDevices
    ) {

      navigator.mediaDevices
        .getUserMedia({ video: true, audio: false })
        .then(Permissions.gotStream)
        .catch(Permissions.handleError);
    } else {
      //console.log( "TODO, media not supported" );
      Permissions.handleError(null);
    }
  }

  static gotStream(stream) {
    console.log("[[GOT STREAM]]", stream);
    // This is used for later to access it from other components
    window.stream = stream;

    // now that we have permissions,  release the streams.   Fixed an issue with older s8 and note 9 android 8 devices
    if (window.stream && typeof window.stream.getTracks === "function") {
      window.stream.getTracks().forEach(track => {
        track.stop();
        track = null;
      });
    }

    Permissions.success();
  }

  static success($redirect = true) {
    if ($redirect) {
      console.log("SUCCESS", router);
      window.webar.tracking.track_action('motion_permission_allow');
      router.push("/webar/start");
    }
  }

  static handleError(e) {
    console.error("PERMISSIOON handleError     ", e);
    window.webar.tracking.track_action('motion_permission_cancel');
    router.push("permission-error");
    // store.view = 'Instructions';
    // store.view = 'PermissionError';
  }
}
