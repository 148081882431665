<template>
  <v-orientation class="landscape" v-if="landscape" />
  <div class="home pagefull">
    
    <div class="home__bg">
      <!-- <img
        v-if="isMobile()"
        :src="require('@/assets/images/Photo/home_dots.png')"
        :srcset="require('@/assets/images/Photo/home_dots@2x.png') + ' 2x'"
        alt=""
      />
      <img
        v-if="!isMobile()"
        :src="require('@/assets/images/Photo/home_dots_large.png')"
        :srcset="
          require('@/assets/images/Photo/home_dots_large@2x.png') + ' 2x'
        "
        alt=""
      /> -->
    </div>
    <div class="home__overlay" :class="{ show: showMessage }"></div>
    <div class="home__message" :class="{ show: showMessage }">
      <h2>Lights, Camera, Froot Loops!</h2>
      <div class="home__message__info">
        <p>To explore Froot Loops World, we’ll need permission to access your camera.</p>
      </div>
      <!-- <img
        :src="require('@/assets/images/Icon/TapAllow.png')"
        :srcset="require('@/assets/images/Icon/TapAllow@2x.png') + ' 2x'"
        alt="Camera"
        width="128"
        height="64"
      /> -->
    </div>
    <div class="home__logo">
      <img
        class="logo"
        :src="require('@/assets/images/home/frootLoopsWorld_Logo.png')"
        :srcset="require('@/assets/images/home/frootLoopsWorld_Logo@2x.png') + ' 2x'"
        alt="Toucan Sam flying Logo"
      />
      <img
        class="toucan"
        :src="require('@/assets/images/home/Toucan_Sam_flying.png')"
        :srcset="
          require('@/assets/images/home/Toucan_Sam_flying@2x.png') + ' 2x'
        "
        alt="Toucan Sam flying"
      />
    </div>
    <div class="home__text">Ready to discover every color of Froot Loops<sup>®</sup>?</div>
    <img
      class="home__8wall"
      v-if="isMobile()"
      :src="require('@/assets/images/Icon/powered_by.png')"
      :srcset="require('@/assets/images/Icon/powered_by@2x.png') + ' 2x'"
      alt="Powered by 8th WALL"
    />
    <img
      class="home__8wall"
      v-if="!isMobile()"
      :src="require('@/assets/images/Icon/powered_by_large.png')"
      :srcset="require('@/assets/images/Icon/powered_by_large@2x.png') + ' 2x'"
      alt="Powered by 8th WALL"
    />
    <img
      class="home__CTA"
      :onClick="showButton"
      :src="require('@/assets/images/UI/CTA/LetsGo.png')"
      alt="Let's Go"
    />
  </div>
</template>
<script>

import Orientation from "@/components/Orientation";
import Permissions from "@/lib/Permissions";
import store from "../Store";
export default {
  name: "Home",
  components: {
    "v-orientation": Orientation
    },
  data: function () {
    return {
      showMessage: false,
      landscape: false
    };
  },
  mounted() {
    console.log('route.params  ', store.state.group);
    window.permissionObj = Permissions;
    // this.showMessage = true;
    if (store.state.group === -1 && localStorage.getItem("k_group") != null) {
      store.group = JSON.parse(localStorage.getItem("k_group"));
      console.log('route.params  ', store.state.group);
    }
    // window.webar.tracking.track_gamestate('home');
    window.addEventListener("resize", this.orientationChange);
  },
  unmounted: function () {
    window.removeEventListener("resize", this.orientationChange);
  },
  methods: {
    showButton() {
      window.webar.tracking.track_gamestate('motion_permission');
      this.showMessage = true;
      Permissions.permissions();
    },
    isMobile() {
      return screen.width <= 760 ? true : false;
    },
    orientationChange: function () {
      if (window.innerWidth > window.innerHeight) {
        this.landscape = true;
        store.landscape = true;
        console.log("landscape True");
      } else {
        console.log("landscape FALSE");
        this.landscape = false;
        store.landscape = false;
      }
    }
  }
};
</script>


<style lang="scss" scoped>
.landscape {
  z-index: 2000;
}
.home {
  // background-color: $black;
  background-size: cover;
  background-image: url("~@/assets/images/home/BG_Landing_Page.png");
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  grid-template-rows: repeat(1, 1fr);
  align-items: center;
  justify-items: center;
  justify-content: flex-end;
  // padding: 0 26px;
  position: relative;
  @media only screen and (min-resolution: 2dppx) {
    background-image: url("~@/assets/images/home/BG_Landing_Page@2x.png");
  }

  > * {
    margin-bottom: 20px;
    @include breakpoint(640px, "from") {
      margin-bottom: 60px;
    }
    z-index: 5;
  }

  &.show {
  }

  &__bg {
    bottom: -3px;
    margin: 0;
    position: absolute;
    width: 100%;
    z-index: 2;

    img {
      width: 100%;
    }

  }

  &__logo {
    height: 65%;
    position: relative;
    width: 53%;
    top:3%;
    @include breakpoint(640px, "from") {
      top:5%;
      width: 45%;
    }    
    @include breakpoint(320px, "to") {
      height: 55%;
      width: 43%;
    }
    img {
      // bottom: 7%;
      // left: 50%;
      // position: absolute;
      // transform: translateX(-50%);

      &:first-child {
        // top: 0;
        width: 100%;
      }

      &:last-child {
        text-align: center;
        margin: -8% 0 0 0;
        width: 100%;
        @include breakpoint(640px, "from") {
          // width: 63%;
        }
      }
    }
  }

  &__overlay {
    background-color: $black20;
    backdrop-filter: blur(5px);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 9;
    display: none;

    &.show {
      display: block;
    }
  }
  &__8wall {
    width: 30%;
  }
  &__text {
    color: $white;
    font-weight: $weight-bold;
    font-size: $size-large;

    text-shadow: 0px 0px 5px #00000070;
    @include breakpoint(320px, "to") {
      font-size: $size-med;
    }
    text-align: center;
    width: 90%;
    z-index: 1;
    sup {
      font-size: 12px;
    }
  }
  &__CTA {
    width: 80%
  }
  &__message {
    text-align: center;
    color: $white;
    display: none;
    margin: 0;
    width: 100%;
    z-index: 10;

    &.show {
      align-items: center;
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 50px;
      left: 0;
      padding: 0 26px;

      > * {
        margin-bottom: 10px;
      }
    }

    &__info {
      align-items: center;
      display: flex;
      justify-content: center;

      > * {
        margin-left: 5px;

        &:last-child {
          margin-left: 0;
        }
      }
    }
  }
}
</style>