<template>
  <div class="Header">
    <div class="Header___bg"></div>
    <img
      class="Header___logo activate"
      :src="require('@/assets/images/UI/Header.png')"
      alt="1"
      :onClick="reCenter"
    />
    <div class="Header___CTA" v-if="showButton">
      <img
        class="reset activate"
        :src="require('@/assets/images/UI/CTA/Reset.png')"
        alt="1"
        :onClick="reCenter"
      />
      <img
        class="close activate"
        :src="require('@/assets/images/UI/X.png')"
        alt="1"
        :onClick="close"
      />
    </div>
  </div>
</template>

<script>
import router from "../router/index";

export default {
  name: "Header",
  data: function() {
    return {
      scene: null,
      showButton: false,
    };
  },
  methods: {
    reCenter: function() {
      try {
        console.log("Window  ", window);
        console.log("this.scene  ", this.scene);
        this.scene.emit("recenter", {});
        console.log("RECENTERED CALLED  ");
        console.log("this.el.sceneEl  ", this.el.sceneEl);
      } catch (e) {
        console.log("ERROR", e);
      }
    },
    close: function() {
      try {
        router.push("exit");
      } catch (e) {
        console.log("ERROR");
      }
    },
  },
  beforeMount: function() {},
  watch: {
    $route() {
      this.showButton = this.$route.path.indexOf("start") !== -1 ? false : true;
    },
  },
  mounted: function() {
    this.scene = document.getElementById("scene");
  },
};
</script>
<style scoped lang="scss">
.Header {
  position: absolute;
  width: 100%;
  // padding: 20px;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
  z-index: 1000;
  > * {
    z-index: 2;
  }
  &___bg {
    position: absolute;
    width: 100%;
    height: 64px;
    background-color: $brandAccent;
    content: "";
    // z-index: 0;
  }
  &___logo {
    position: relative;
    width: 177px;
    z-index: 2;
    margin: 10px;
    height: 105px;
  }
  &___CTA {
    float: right;
    position: relative;
    padding: 10px 10px 0 0;
    text-align: right;

    .reset {
      width: 88px;
      height: 42px;
      margin: 0 10px;
    }
    .close {
      width: 42px;
      height: 42px;
    }
  }
}
.activate {
  pointer-events: auto;
}
.photoNote {
  background: rgb(0 0 0 / 33%);
  border-radius: 6px;
  -webkit-backdrop-filter: blur(23px);
  backdrop-filter: blur(23px);
  font-size: 1.2rem;
  color: #fff;
  padding: 10px;
  opacity: 0;
}
</style>
